<template>
	<div class="w">
		<div class="nav-box">
			<router-link to="/login" active-class="selected-link">会员登录</router-link>
			<router-link to="/register" active-class="selected-link">申请注册</router-link>
			<el-button class="collect" text @click="collect()">收藏本站</el-button>
		</div>
		<div class="wrap">
			<div class="login-info">
				<div class="account-wrap">
					<el-image class="logo-img" :src="logoImg"></el-image>
					<div class="tips">
						<div class="tips-title">公告： </div>
<!--						<div class="tips-info">本网站只限缤纷鲜花经营者采购专用</div>-->
<!--						<div class="tips-info">体验账号：9996 密码：123456。 </div>-->
            <div class="tips-info">缤纷鲜花网：<a target="_blank" href="https://sale.brightenflower.cn/#/login/account">sale.brightenflower.cn</a></div>
						<div class="tips-info">想了解缤纷盆栽的资料及图片请登入缤纷盆栽网：<a target="_blank"
								href="http://www.brightenplant.cn/">www.brightenplant.cn</a></div>
					</div>
				</div>
				<div class="main-wrap">
					<router-view></router-view>
				</div>
			</div>
			<div class="app-wrap">
				<div class="app-box">
					<el-image class="app-weChat" :src="flowerApp"></el-image>
					<div>缤纷鲜花APP下载</div>
				</div>
				<div class="app-box">
					<el-image class="app-weChat" :src="plantApp"></el-image>
					<div>缤纷盆栽APP下载</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onBeforeUnmount
	} from "vue";
	// 进入VUE时首先调用的钩子函数
	onMounted(() => {
		//添加背景图片
		document.body.style.height = '100vh';
		document.body.style.width = '100vw';
		document.body.style.overflow = 'hidden';
		document.body.style.backgroundRepeat = 'no-repeat';
		document.body.style.backgroundPosition = 'center center';
		document.body.style.backgroundSize = 'cover';
		document.body.style.backgroundImage = 'url(' + require('../../assets/image/bg.png') + ')';
	});
	// 离开VUE时调用的钩子函数
	onBeforeUnmount(() => {
		// 背景色为白色
		document.body.style.backgroundImage = '';
		document.body.style.overflowY = 'auto';
	});
	//logo
	const logoImg = ref(require('../../assets/image/loginLogo.png'));
	//app
	const flowerApp = ref(require('../../assets/image/flower_app.png'));
	const plantApp = ref(require('../../assets/image/plant_app.png'));
	// 收藏本站
	const collect = () => {

	};
</script>

<style lang="scss" scoped>
	.w {
		margin: 30px auto;
		.nav-box {
			margin-bottom: 30px;
			display: flex;
			justify-content: center;
			align-items: center;

			a,
			.el-button.is-text {
				display: inline-block;
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				margin-right: 30px;
			}

			.selected-link {
				color: #FC4D9A;
				border-bottom: 4px solid #FE5BA3;
			}

			.el-button.is-text {
				background-color: none;
				padding: 0px;
			}

			.el-button.is-text:not(.is-disabled):hover,
			.el-button.is-text:not(.is-disabled):focus {
				background-color: rgba(255, 255, 255, 0);
				border: none;
			}
		}

		.wrap {
			display: flex;

			.login-info {
				background-color: #FFFFFF;
				width: 1200px;
				box-shadow: 0px 0px 10px #BBBBBB;
				border-radius: 10px;
				display: flex;

				.account-wrap {
					// background: url('../../assets/image/loginBg.png') no-repeat center center;
					// background-size: cover;
					width: 600px;
					//min-height: 712px;
					border-radius: 20px 0px 0px 20px;
					text-align: center;

					.logo-img {
						width: 566px;
						height: 314px;
						margin: 5px 0px;
					}

					.tips {
						font-size: 24px;
						font-weight: 400;
						//color: #FFFFFF;
						width: 500px;
						margin: 0 auto;
						text-align: left;
						line-height: 48px;

						.tips-title {
							font-size: 28px;
							font-weight: 500;
							//color: #FFFFFF;
							line-height: 56px;
						}

						a {
							color: #FE5BA3;
						}
					}
				}

				.main-wrap {
					width: 600px;
					//min-height: 712px;
					border-radius: 0px 20px 20px 0px;
				}
			}
			.app-wrap{
				margin-left:20px;
				width: 200px;
				//min-height: 712px;
				box-shadow: 0px 0px 10px #BBBBBB;
				background-color: #FFFFFF;
				border-radius: 10px;
				padding:10px;
				text-align: center;
				.app-box{
					margin-top:20px;
					font-size:20px;
					font-weight: bold;
				}
			}
		}
	}
</style>